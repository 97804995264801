import React from "react"

import CardImage from "../components/cardImage"
import Heading from "../components/heading"

const ChiSiamo = () => (
  <>
    <Heading
        title="Chi siamo"
        text="Siamo un gruppo affiatato di professionisti che crede nella dedizione al lavoro, nella competenza e nella comprensione delle esigenze dei clienti quali cardini fondamentali per plasmare le migliori strategie difensive."
    />
      <div className="row">
        <div className="col-12 col-xl-8 offset-xl-2">
          <div className="container-fluid">
            <div className="row">
          <div className="col-lg-12 col-sm-6 card-wrapper">
          <CardImage
            img="https://via.placeholder.com/192x256"
            title="Francesco Salomoni"
            subtitle="Avvocato"
            text="Si è laureato in Giurisprudenza presso l’Università di Modena e Reggio Emilia.<br>
            È avvocato dal 1993."
          />
          </div>
          <div className="col-lg-12 col-sm-6 card-wrapper">
          <CardImage
            img="https://via.placeholder.com/192x256"
            title="Elisabetta Preci"
            subtitle="Avvocato"
            text="Si è laureata in Giurisprudenza con lode presso l’Università di Modena e Reggio Emilia.<br>
            È avvocato dal 2012."
          />
          </div>
          <div className="col-lg-12 col-sm-6 card-wrapper">
          <CardImage
            img="https://via.placeholder.com/192x256"
            title="Pietro Puliatti"
            subtitle="Avvocato"
            text="Si è laureato in Giurisprudenza con lode presso l’Università di Modena e Reggio Emilia.<br>
È stato visiting student presso la <em>Hofstra Law School</em> di Hempstead, NY.<br>
Ha conseguito il titolo di Dottore di ricerca in Diritto del Lavoro presso l’Università di Padova.<br>
È avvocato dal 2012."
          />
          </div>
          <div className="col-lg-12 col-sm-6 card-wrapper">
          <CardImage
            img="https://via.placeholder.com/192x256"
            title="Salvatore Puliatti"
            subtitle="Professore Avvocato"
            text="Si è laureato in Giurisprudenza con lode e dignità di stampa presso l’Università di Modena e Reggio Emilia.<br> Ha insegnato materie romanistiche presso le Facoltà di Giurisprudenza dell’Università di Messina, di Modena e Reggio Emilia e dell’Università Cattolica del Sacro Cuore.<br> È Professore Ordinario di Diritto Romano presso l’Università di Parma.<br> È avvocato dal 1981."
          />
          </div>
          <div className="col-lg-12 col-sm-6 card-wrapper">
          <CardImage
            img="https://via.placeholder.com/192x256"
            title="Eugenia Franciosi"
            subtitle="Professore Avvocato"
            text="Si è laureata in Giurisprudenza con lode presso l’Università di Modena e Reggio Emilia.<br>
            In qualità di ricercatrice confermata ha insegnato materie romanistiche presso la Facoltà di Giurisprudenza dell’Università di Bologna.<br>
            È avvocato dal 1981."
          />
          </div>
          <div className="col-lg-12 col-sm-6 card-wrapper">
          <CardImage
            img="https://via.placeholder.com/192x256"
            title="Cecilia Bondi"
            subtitle="Avvocato"
            text="Si è laureata in Giurisprudenza con lode presso l’Università di Modena e Reggio Emilia.<br>
È stata <em>visiting student</em> presso la Facultad de Derecho della Universidad Pompeu Fabra di Barcellona e presso la School of Law della Liverpool John Moores University.<br>
Ha conseguito il titolo di Dottore di ricerca europeo in Scienze Giuridiche presso l’Università di Modena e Reggio Emilia.<br>
È avvocato dal 2014."
          />
          </div>
          <div className="col-lg-12 col-sm-6 card-wrapper">
          <CardImage
            img="https://via.placeholder.com/192x256"
            title="Michela De Santis"
            subtitle="Avvocato"
            text="Si è laureata in giurisprudenza presso l’Università Commerciale Luigi Bocconi. <br> Ha conseguito il titolo di Dottore di ricerca in Scienze Giuridiche presso l’Università di Modena e Reggio Emilia.<br>È stata <em>visiting student</em> presso l’<em>Institut de recherche juridique de la Sorbonne (IRJS), Paris I – Panthéon Sorbonne</em>.<br>È stata assegnista di ricerca nei settori di diritto commerciale / diritto processuale civile nell’Università di Modena e Reggio Emilia ed è autrice di pubblicazioni scientifiche del settore.<br>È docente a contratto di <em>Insolvency Law</em> nell’Università di Modena e Reggio Emilia.<br>È avvocato dal 2014."
          />
          </div>
          <div className="col-lg-12 col-sm-6 card-wrapper">
          <CardImage
            img="https://via.placeholder.com/192x256"
            title="Elisabetta Verzella "
            subtitle="Dottoressa"
            text="Si è laureata in Giurisprudenza presso l’Università di Modena e Reggio Emilia.<br> È praticante avvocato dal 2021"
          />
          </div>
          </div>
          </div>
        </div>
      </div>
    </>
)

export default ChiSiamo
