import React from "react"

const Heading = (props) => (
  <div className="heading">
    <div className="row">
      <div className="col-xl-10 offset-xl-1">
        <h2>{props.title}</h2>
        <p>{props.text}</p>
      </div>
    </div>
  </div>
)

export default Heading
