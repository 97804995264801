import React from "react"

import cardImageStyles from "./cardImage.module.scss"

const CardImage = (props) => (
  <section className={cardImageStyles.card}>
    <div className={cardImageStyles.cardinfo}>
      <h3>{props.title}</h3>
      {props.subtitle && <label className={cardImageStyles.label}>{props.subtitle}</label>}
      <hr/>
      <p className={cardImageStyles.text} dangerouslySetInnerHTML={{ __html: props.text }}></p>
    </div>
  </section>
)

export default CardImage
